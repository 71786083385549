import React, {  } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas);
library.add(far);

export const MainNav = () => {
    // const [user, setUser] = useState({});

    // useEffect(() => {
    //     const subscription = accountService.user.subscribe(x => setUser(x));
    //     return subscription.unsubscribe;
    // }, []);

    // // only show nav when logged in
    // if (!user) return null;

    return (
        <div>
            <ul className="nav-group"  >
                <li className="nav-group-item">
                    <NavLink to="/" className='nav-link'>
                        <div className='nav-link-icon-wrapper'>
                            <FontAwesomeIcon icon="fa-solid fa-gauge-simple-high" />
                        </div>
                        <span className="title">Dashboard</span>
                    </NavLink>
                </li>

                <li className="nav-group-item">
                    <NavLink to="/business" className='nav-link'>
                        <div className='nav-link-icon-wrapper'>
                            <FontAwesomeIcon icon="fa-solid fa-suitcase" />
                        </div>
                        <span className="title">Businesses</span>
                    </NavLink>
                </li>

                <li className="nav-group-item">
                    <NavLink to="/contacts" className='nav-link'>
                        <div className='nav-link-icon-wrapper'>
                            <FontAwesomeIcon icon="fa-regular fa-user" />
                        </div>
                        <span className="title">Contacts</span>
                    </NavLink>
                </li>

                <li className="nav-group-item">
                    <NavLink to="/property" className='nav-link'>
                        <div className='nav-link-icon-wrapper'>
                            <FontAwesomeIcon icon="fa-solid fa-location-dot" />
                        </div>
                        <span className="title">Properties</span>
                    </NavLink>
                </li>

                <li className="nav-group-item">
                    <NavLink to="/engagement" className='nav-link'>
                        <div className='nav-link-icon-wrapper'>
                            <FontAwesomeIcon icon="fa-solid fa-handshake" />
                        </div>
                        <span className="title">Engagement</span>
                    </NavLink>
                </li>

                {/* <li className="nav-group-item">
                    <NavLink to="/engagementlist" className='nav-link'>
                        <div className='nav-link-icon-wrapper'>
                            <FontAwesomeIcon icon="fa-solid fa-handshake" />
                        </div>
                        <span className="title">Engagements</span>
                    </NavLink>
                </li> */}
                <li className="visually-hidden    nav-group-item">
                    <NavLink to="/lists" className='nav-link'>
                        <div className='nav-link-icon-wrapper'>
                            <FontAwesomeIcon icon="fa-solid fa-table-list" />
                        </div>
                        <span className="title">Engagement Lists</span>
                    </NavLink>
                </li>

                <li className="nav-group-item">
                    <NavLink to="/campaigns" className='nav-link'><div className='nav-link-icon-wrapper'>
                        <FontAwesomeIcon icon="fa-solid fa-flag" />
                    </div>
                        <span className="title">Campaigns</span>
                    </NavLink>

                </li>

                <li className="visually-hidden nav-group-item">
                    <NavLink to="/newsletter" className='nav-link'>
                        <div className='nav-link-icon-wrapper'>
                            <FontAwesomeIcon icon="fa-solid fa-newspaper" />
                        </div>
                        <span className="title">Newsletters</span>
                    </NavLink>
                </li>

                <li className="active nav-group-item">
                    <NavLink to="/analytics" className='nav-link'>
                        <div className='nav-link-icon-wrapper'>
                            <FontAwesomeIcon icon="fa-solid fa-chart-bar" />
                        </div>
                        <span className="title">Analytics</span>
                    </NavLink>
                </li>

                <li className="visually-hidden nav-group-item">
                    <NavLink to="/about" className='nav-link'>
                        <div className='nav-link-icon-wrapper'>
                            <FontAwesomeIcon icon="fa-solid fa-info" />
                        </div>
                        <span className="title">About Monitor CRMS</span>
                    </NavLink>
                </li>

                <li className="visually-hidden nav-group-item">
                    <NavLink to="/guide" className='nav-link'>
                        <div className='nav-link-icon-wrapper'>
                            <FontAwesomeIcon icon="fa-solid fa-book" />
                        </div>
                        <span className="title">Monitor CRMS User Guide</span>
                    </NavLink>
                </li>
            </ul>
            {/* <nav className="navbar navbar-expand navbar-dark bg-dark">
                <div className="navbar-nav">
                    <NavLink exact to="/" className="nav-item nav-link">Home</NavLink>
                    <NavLink to="/profile" className="nav-item nav-link">Profile</NavLink>
                    {user.role === Role.Admin &&
                        <NavLink to="/admin" className="nav-item nav-link">Admin</NavLink>
                    }
                    <a onClick={accountService.logout} className="nav-item nav-link">Logout</a>
                </div>
            </nav>
            <Route path="/admin" component={AdminNav} /> */}
        </div>
    );
}

// function AdminNav({ match }) {
//     const { path } = match;

//     return (
//         <nav className="admin-nav navbar navbar-expand navbar-light">
//             <div className="navbar-nav">
//                 <NavLink to={`${path}/users`} className="nav-item nav-link">Users</NavLink>
//                 <NavLink to={`${path}/contacts`} className="nav-item nav-link">Contacts</NavLink>
//             </div>
//         </nav>
//     );
// }

