import { useEffect, useState } from "react";
import { activityService } from "services/campaigns/activity.service";
import { decode } from "html-entities"
import { LandingLoadingLayout } from "layouts/LandingLoadingLayout";
import PlainClientLogoLayout from "layouts/PlainClientLogoLayout";

export const ViewEmailOnline = () => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [hasError, setHasError] = useState(null)

    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    //clientIdHash, campaignActivityHistoryIdHash
    const clientIdHash = urlParams?.get('ci')?.replace(/\s/g, '+');
    const campaignActivityHistoryIdHash = urlParams?.get('ahi')?.replace(/\s/g, '+');

    useEffect(() => {
        setLoading(true)

        if (clientIdHash === undefined) {
            setHasError("The link is invalid or expired. Please contact the sender for more information.")
            setLoading(false)
        }
        else {
            activityService.getEmailHtml(clientIdHash, campaignActivityHistoryIdHash)
                .then((res) => {
                    setData(res.html)
                })
                .catch((err) => {
                    setHasError("The link is invalid or expired. Please contact the sender for more information.")
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [])

    const iframeStyle = {
        minHeight: "100vh",
        padding: 0,
        margin: 0,
        overflow: "auto",
        display: "block"
    }

    return (
        <>
            {loading &&
                <LandingLoadingLayout loading={loading} />
            }
            {
                hasError &&
                <PlainClientLogoLayout clientLogoPath={data?.clientLogo}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    }}>
                        <div style={{
                            backgroundColor: "#f5f5f5",
                            width: "500px",
                            padding: "20px 50px",
                            borderRadius: "10px",
                            textAlign: "center",
                            margin: "50px auto"
                        }}>
                            {hasError}
                        </div>
                    </div>
                </PlainClientLogoLayout>
            }

            {!hasError && data &&
                <iframe title="EmailEditor" srcDoc={decode(data)} className="container-fluid" style={iframeStyle} ></iframe>
            }
        </>
    )
}