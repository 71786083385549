import { accountService, alertService } from 'services';
import { config } from 'config';


const xsrfCookie = () => {
    const cookie = document.cookie
        .split('; ')
        .find(row => row.startsWith('XSRF-TOKEN'));

    const xsrfToken = cookie ? cookie.split('=')[1] : null;
    const xsrfHeader = {
        'X-CSRF-TOKEN': xsrfToken || ''
    }

    return xsrfHeader;
}
// Find and extract the XSRF-TOKEN cookie value

export const fetchWrapper = {
    get,
    post,
    put,
    delete: _delete
}

function get(url, abortSignal) {
    const requestOptions = {
        method: 'GET',
        headers: { ...addOrigin(), ...authHeader(url) }
    };

    const options = { ...requestOptions }
    if (abortSignal)
        options.signal = abortSignal;

    return fetch(url, options).then(handleResponse);
}

function post(url, body) {
    const requestOptions = {
        method: 'POST',
        headers: { ...baseHeader(), ...authHeader(url), ...xsrfCookie() },
        credentials: 'include',
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function put(url, body) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...baseHeader(), ...authHeader(url), ...xsrfCookie() },
        credentials: 'include',
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
    const requestOptions = {
        method: 'DELETE',
        credentials: 'include',
        headers: { ...baseHeader(), ...authHeader(url), ...xsrfCookie() },
    };
    return fetch(url, requestOptions).then(handleResponse);
}

// helper functions

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const user = accountService.userValue;
    const isLoggedIn = user && user.jwtToken;
    const isApiUrl = url.startsWith(config.apiUrl);
    if (isLoggedIn && isApiUrl) {
        return { Authorization: `MonitorCrmsBearer ${user.jwtToken}` };
    } else {
        return {};
    }
}

function baseHeader() {
    return { "Content-Type": "application/json", ...addOrigin() }

}

function addOrigin() {
    return {
        "Access-Control-Allow-Origin": `"${config.siteOrigin}"`
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if ([401, 403].includes(response.status) && accountService.userValue) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                accountService.logout();
            }

            const error = (data && data.message) || response.statusText;
            if (error)
                return Promise.reject(error);

            if (data && data?.HasErrors) {
                alertService.error(`Something went wrong. Please try again later.
                    <br> ${JSON.stringify(data.Errors)}`);
            }

            alertService.error(`Status: ${response.status} ${response.statusText} (Something went wrong, please refresh the page and try again)`);

            // console.log('handleResponse', response, data)

            throw Error(response.statusText);

            return data;
        }

        return data;
    });
}