import { Suspense, lazy, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { accountService } from 'services';

// import Login from './Login';
// import Register from './Register';
// import VerifyEmail from './VerifyEmail';
// import ForgotPassword from './ForgotPassword';
// import ResetPassword from './ResetPassword';
// import LoginLayout from 'layouts/LoginLayout';
import LoadingProgressLinear from 'components/LoadingProgressLinear';

const Login = lazy(() => import("./Login"))
const Unsubscribe = lazy(() => import("./Unsubscribe"))
const Register = lazy(() => import('./Register'))
const VerifyEmail = lazy(() => import('./VerifyEmail'))
const ForgotPassword = lazy(() => import('./ForgotPassword'))
const ResetPassword = lazy(() => import('./ResetPassword'))
const LoginLayout = lazy(() => import('layouts/LoginLayout'))

function Account({ history, match, location }) {
    const { path } = match;

    useEffect(() => {
        // redirect to home if already logged in
        if (accountService.userValue) {
            const { from } = location.state || { from: { pathname: "/" } };
            history.push(from);

            //history.push('/');
        }
    }, []);

    return (
        <Suspense fallback={<LoadingProgressLinear isLoading={true} />}>
            <LoginLayout>
                <Switch>
                    <Route path={`${path}/login`} component={Login} />
                    <Route path={`${path}/unsubscribe`} component={Unsubscribe} />
                    <Route path={`${path}/register`} component={Register} />
                    <Route path={`${path}/verify-email`} component={VerifyEmail} />
                    <Route path={`${path}/forgot-password`} component={ForgotPassword} />
                    <Route path={`${path}/reset-password`} component={ResetPassword} />
                </Switch>
            </LoginLayout>
        </Suspense>


    );
}

export default Account;