import { config } from 'config';
import { fetchWrapper } from 'helpers';
import { alertService } from './alert.service';

const apiUrl = `${config.apiUrl}/api/help`;

export const helpService = {
    get: function () {
        return fetchWrapper.get(apiUrl);
    },
    getByPathUrl: function (pathUrl) {
        let url = `${apiUrl}/GetByPathUrl?pathUrl=${pathUrl}`
        return fetchWrapper.get(url);
    },
    getById: function (id) {
        let url = `${apiUrl}/${id}`
        return fetchWrapper.get(url);
    },
    create: async function (data) {
        //add api post code
        await fetchWrapper.post(apiUrl, data)
            .then(response => {
                //assign new id to submitData to be used in the form for update
                data.id = response.data.id;
            });

        return data.id;
    },
    update: async function (data) {
        //add api post code
        const url = `${apiUrl}/${data.id}`;
        await fetchWrapper.put(url, data)
            .then(response => {
                //assign new id
                if (response.data)
                    data.id = response.data.id;

                if (response.errors) {
                    alertService.error(JSON.stringify(response.errors));
                }

            });

        return;
    },
    delete: async function (id) {
        const url = `${apiUrl}/${id}`;
        return await fetchWrapper.delete(url);
    },
    archive: async function (id) {
        const url = `${apiUrl}/Archive/${id}`;
        return await fetchWrapper.delete(url);
    },
};
