import { faCompressAlt, faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Offcanvas } from "react-bootstrap";
import { useSidebarPanelContext, useSidebarPanelDispatchContext } from "./SidebarPanelContext";

export const SidebarPanelWrapper = ({ title, id, children, ...props }) => {
    const context = useSidebarPanelContext();
    const dispatch = useSidebarPanelDispatchContext();

    const isShow = context?.id && (context.id === id);

    const maximize = context?.maximize;

    const hide = () => {
        dispatch({ type: "hide" });
    };

    const setMaximize = (value) => {
        dispatch({ ...context, maximize: value });
    };

    return (
        <>
            <Offcanvas show={isShow} onHide={hide} placement="end"  backdrop={false} scroll={true} {...props}
                style={maximize ? { width: '100%' } : {}}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{title} </Offcanvas.Title>
                    <Button className="ms-auto" variant="light" onClick={() => setMaximize(!maximize)}>
                        <FontAwesomeIcon icon={maximize ? faCompressAlt : faExpandAlt} />
                    </Button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {children}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
