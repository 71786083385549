import React, { useState, useEffect } from 'react';

import { contactsService } from 'services';

function Contacts() {
    const [contacts, setContacts] = useState(null);

    useEffect(() => {
        contactsService.getAll().then(x => setContacts(x));
    }, []);

    return (
        <>


            <table className="table table-striped">
                <thead>
                    <tr>
                        <th style={{ width: '30%' }}>Name</th>
                        <th style={{ width: '30%' }}>Email</th>
                        <th style={{ width: '30%' }}>Position</th>
                        <th style={{ width: '10%' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {contacts && contacts.map(contact =>
                        <tr key={contact.id}>
                            <td>{contact.firstName} {contact.surname} </td>
                            <td>{contact.email}</td>
                            <td>{contact.position}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>

    );
}

export { Contacts };