
import { config } from 'config';
import { fetchWrapper } from 'helpers';

const baseUrl = `${config.apiUrl}/api/contact`;

export const contactsService = {
    get,
    getById,
    getRecipientByKeyword,
    getByFilters,
    getDetailsByFields: async function (param) {
        return fetchWrapper.post(`${baseUrl}/GetDetailsByFields`, param);
    },
    getByFilterAdvanced,
    getActivityAudiencePaged,
    getActivityAudience,
    getActivityAudienceStats,
    getActivityValidAudienceStats
};

function getByFilters(param){
    return fetchWrapper.post(`${baseUrl}/GetByFilters`, param);
}


function getByFilterAdvanced(param){
    return fetchWrapper.post(`${baseUrl}/GetByFilterAdvanced`, param);
}

function getActivityAudiencePaged(param){
    return fetchWrapper.post(`${baseUrl}/GetActivityAudiencePaged`, param);
}

function getActivityAudience(param){
    return fetchWrapper.post(`${baseUrl}/GetActivityAudience`, param);
}

function getActivityAudienceStats(param){
    return fetchWrapper.post(`${baseUrl}/GetActivityAudienceStats`, param);
}

function getActivityValidAudienceStats(param){
    return fetchWrapper.post(`${baseUrl}/GetActivityValidAudienceStats`, param);
}

function get(page, pageSize) {
    const url = `${baseUrl}?page=${page}&pageSize=${pageSize}`;
    return fetchWrapper.get(url);
}

function getById(id) {
    return fetchWrapper.get(`${baseUrl}/${id}`);
}

function getRecipientByKeyword(page, pageSize, keyword) {
    let url = `${baseUrl}/GetRecipientByKeyword?page=${page}&pageSize=${pageSize}`;

    if(keyword){
        url += `&keyword=${keyword}`;
    }

    return fetchWrapper.get(url);
}

