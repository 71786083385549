import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmModal = ({ title, content, onConfirm, onCancel, isShow }) => {
    const [isloading, setLoadingModal] = useState(false);
  
    const handleClose = () => {
      onCancel();
    };
  
    const handleConfirm = async (e) => {
      e.preventDefault();
      setLoadingModal(true);
      try {
        // Perform asynchronous action (e.g., API call) in onConfirm
        await onConfirm();
      } catch (error) {
        // Handle error if needed
      } finally {
        setLoadingModal(false);
        handleClose(); // Close the modal after the action is complete
      }
    };
  
    return (
      <Modal show={isShow} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleClose} disabled={isloading}>
            No
          </Button>
          <Button variant="outline-danger" onClick={handleConfirm} disabled={isloading}>
          {isloading ? 'Loading...' : 'Yes'}
        </Button>
        </Modal.Footer>
      </Modal>
    );
  };

export default ConfirmModal;