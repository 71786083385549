import LoadingProgressLinear from "components/LoadingProgressLinear"
import { Stack } from "react-bootstrap"
import HelpComment from "./HelpComment"
import HelpCommentCreate from "./HelpCommentCreate"

const { useState } = require("react")
const { useEffect } = require("react")
const { helpCommentService } = require("services/helpComment.service")

const HelpComments = (props) => {
    const { helpId } = props
    const [comments, setComments] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getData();

        return () => {
            setComments(null)
        }
    }, [helpId])

    const getData = () => {
        setLoading(true);
        helpCommentService.getByHelpId(helpId).then(result => {
            setComments(result.data)
            setLoading(false);
        })
    }

    return (
        <>
            {comments && comments.length > 0 &&
                comments.map((comment, index) =>
                    <Stack gap={3} key={comment.id}>
                        <LoadingProgressLinear isLoading={loading} />
                        <HelpComment comment={comment} reloadComments={getData}></HelpComment>
                    </Stack>
                )
            }
            <HelpCommentCreate helpId={helpId} reloadComments={getData} />
        </>
    )
}


export default HelpComments