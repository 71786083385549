import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { Button, Card, InputGroup } from "react-bootstrap";
import { helpCommentService } from "services/helpComment.service";
import LoadingProgressLinear from "components/LoadingProgressLinear";
import { useState } from "react";

;
const HelpCommentCreate = ({ helpId, reloadComments }) => {
    const [loading, setLoading] = useState(false)
    const initialValues = { message: "", CrmsHelpId: helpId }

    const submit = (values, actions) => {
        setLoading(true)
        helpCommentService.create(values).then(result => {
            setLoading(false)
            actions.resetForm();
            reloadComments(); //reload to get the new data
        });
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <LoadingProgressLinear isLoading={loading} />
                    <Formik initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, actions) => submit(values, actions)}>
                        {({ errors, touched, values, handleReset }) => (
                            <Form>
                                <Field as="textarea" name="message" placeholder="Message" className="form-control mb-3" />
                                {errors.message && touched.message && (
                                    <div className="text-danger">{errors.message}</div>
                                )}
                                <InputGroup>
                                    <Button type="reset" variant="outline-dark" onClick={() => handleReset}>Reset</Button>
                                    <Button type="submit" variant="outline-success">Submit</Button>
                                </InputGroup>
                            </Form>
                        )}
                    </Formik>

                </Card.Body>
            </Card>
        </>
    );
}

const validationSchema = Yup.object().shape({
    message: Yup.string().required('Message is required'),
});

export default HelpCommentCreate;