import { useEffect, useState } from "react"
import { config } from "./config"

export const useConfig = () => {
    const [aadAppApiClientId, setAadAppApiClientId] = useState(null)
    const [aadAppReactClientId, setAadAppReactClientId] = useState(null)

    useEffect(() => {
        config.aadAppApiClientId.subscribe(data => setAadAppApiClientId(data))
        config.aadAppReactClientId.subscribe(data => setAadAppReactClientId(data))

        return () => {
            setAadAppApiClientId(null)
            setAadAppReactClientId(null)
        }
    }, [])

    return {
        aadAppApiClientId,
        aadAppReactClientId
    }
}
