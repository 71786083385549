import { accountService, alertService } from 'services';
import { config } from 'config';

export const fetchWrapperCustomAuth = {
    get,
    post,
    put,
    delete: _delete
}

function get(url, encryptedClientId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...addOrigin(), ...authHeader(encryptedClientId) }
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function post(url, body, encryptedClientId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...baseHeader(), ...authHeader(encryptedClientId) },
        credentials: 'include',
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function put(url, body, encryptedClientId) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...baseHeader(), ...authHeader(encryptedClientId) },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url, encryptedClientId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(encryptedClientId)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

// helper functions

function authHeader(encryptedClientId) {
    return { ci: `${encryptedClientId}` };
}

function baseHeader() {
    return { "Content-Type": "application/json", ...addOrigin() }

}

function addOrigin() {
    return {
        "Access-Control-Allow-Origin": `"${config.siteOrigin}"`
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if ([401, 403].includes(response.status) && accountService.userValue) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                accountService.logout();
            }

            const error = (data && data.message) || response.statusText;
            if (error)
                return Promise.reject(error);

            // if (data && data?.HasErrors) {
            //     alertService.error(`Something went wrong. Please try again later.
            //         <br> ${JSON.stringify(data.Errors)}`);
            // }

            // alertService.error(`Status: ${response.status} ${response.statusText} (Something went wrong, please refresh the page and try again)`);

            // console.log('handleResponse', response, data)

            throw Error(response.statusText);

            return data;
        }

        return data;
    });
}