import { BehaviorSubject } from 'rxjs';

import { config } from 'config';
import { fetchWrapper, history } from 'helpers';

const userSubject = new BehaviorSubject(null);
const baseUrl = `${config.apiUrl}/accounts`;

export const accountService = {
    loginMsUser,
    login,
    logout,
    refreshToken,
    register,
    verifyEmail,
    forgotPassword,
    validateResetToken,
    resetPassword,
    getAll,
    getById,
    create,
    update,
    delete: _delete,
    user: userSubject.asObservable(),
    get userValue() { return userSubject.value },
    unsubscribeGetTestLink(host, clientId, contactId) {

        var querystring = `host=${host}&clientId=${clientId}&contactId=${contactId}`;

        return fetchWrapper.get(`${config.apiUrl}/api/unsubscribe/GetTestLink?${querystring}`);
    },
    authenticateV2User (uid) {
        return fetchWrapper.post(`${baseUrl}/AuthenticateV2User`, uid)

    }

};

function login(email, password) {
    return fetchWrapper.post(`${baseUrl}/authenticate`, { email, password })
        .then(user => {
            // publish user to subscribers and start timer to refresh token
            userSubject.next(user);
            startRefreshTokenTimer();
            return user;
        }).catch(err => {
            logout();
        });
}

function loginMsUser(user) {
    userSubject.next(user);
    startRefreshTokenTimer();
}

function logout() {
    // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
    const user = accountService.userValue;
    const isLoggedIn = user && user.jwtToken;
    if (isLoggedIn) {
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": `"${config.siteOrigin}"`,
                "Authorization": `MonitorCrmsBearer ${user.jwtToken}`
            },
            credentials: 'include',
            body: JSON.stringify({})
        };
        fetch(`${baseUrl}/revoke-token`, requestOptions)
            .catch(err => console.log(err))
            .then(
                () => {
                    stopRefreshTokenTimer();
                    userSubject.next(null);
                    history.push('/account/login');
                }
            );
    }
}

function refreshToken() {
    return fetchWrapper.post(`${baseUrl}/refresh-token`, {})
        .then(user => {
            if (user.HasErrors)
                return;
            // publish user to subscribers and start timer to refresh token
            userSubject.next(user);
            startRefreshTokenTimer();
            return user;
        });
}

function register(params) {
    return fetchWrapper.post(`${baseUrl}/register`, params);
}

function verifyEmail(token) {
    return fetchWrapper.post(`${baseUrl}/verify-email`, { token });
}

function forgotPassword(email) {
    return fetchWrapper.post(`${baseUrl}/forgot-password`, { email });
}

function validateResetToken(token) {
    return fetchWrapper.post(`${baseUrl}/validate-reset-token`, { token });
}

function resetPassword({ token, password, confirmPassword }) {
    return fetchWrapper.post(`${baseUrl}/reset-password`, { token, password, confirmPassword });
}

function getAll() {
    return fetchWrapper.get(baseUrl);
}

function getById(id) {
    return fetchWrapper.get(`${baseUrl}/${id}`);
}

function create(params) {
    return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/${id}`, params)
        .then(user => {
            // update stored user if the logged in user updated their own record
            if (user.id === userSubject.value.id) {
                // publish updated user to subscribers
                user = { ...userSubject.value, ...user };
                userSubject.next(user);
            }
            return user;
        });
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`)
        .then(x => {
            // auto logout if the logged in user deleted their own record
            if (id === userSubject.value.id) {
                logout();
            }
            return x;
        });
}

// helper functions

let refreshTokenTimeout;

function startRefreshTokenTimer() {
    // if(!userSubject.value.jwToken || userSubject.value.jwToken==="")
    //     history.push('/account/login')

    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(userSubject.value.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    refreshTokenTimeout = setTimeout(refreshToken, timeout);
}

function stopRefreshTokenTimer() {
    clearTimeout(refreshTokenTimeout);
}
