const LoadingProgressLinear = ({ isLoading, ...props }) => {
    return (
        <>
            {isLoading &&
                <progress className="loading-progress-linear" {...props} />
            }
        </>
    )
}

export default LoadingProgressLinear;