
import { AppConfigurationClient } from '@azure/app-configuration';
import { BehaviorSubject } from 'rxjs';

const aadAppApiClientIdSubject = new BehaviorSubject(null);
const aadAppReactClientIdSubject = new BehaviorSubject(null);

const client = new AppConfigurationClient(process.env.REACT_APP_AZURE_APP_CONFIG_CONNECTION_STRING); // TODO: Add Connection String

async function run() {
    let aadAppApiClientId = await client.getConfigurationSetting({
        key: "AAD:App:Api:ClientId"
    });
    aadAppApiClientIdSubject.next(aadAppApiClientId.value);

    let aadAppReactClientId = await client.getConfigurationSetting({
        key: "AAD:App:React:ClientId"
    });
    aadAppReactClientIdSubject.next(aadAppReactClientId.value);
}

run().catch((err) => {
    console.error("Failed to run retrieve azure app config:", err);
});

let apiUrl = process.env.REACT_APP_API_URL;
let siteOrigin = process.env.REACT_APP_SITE_ORIGIN;
let monitorV2AppUrl = process.env.REACT_APP_V2APP_URL;

export const config = {
    apiUrl,
    siteOrigin,
    monitorV2AppUrl,
    aadAppApiClientId: aadAppApiClientIdSubject.asObservable(),
    aadAppReactClientId: aadAppReactClientIdSubject.asObservable(),
    isProduction: process.env.NODE_ENV === 'production',
}
