// export default App;
import "react-datepicker/dist/react-datepicker.css";
import 'semantic-ui-css/semantic.min.css'

import { Switch } from 'react-router-dom';
import ErrorBoundary from "components/ErrorBoundary";
import Alert from 'components/Alert';
import AuthenticatedLayout from 'layouts/AuthenticatedLayout';
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import { ViewEmailOnline } from "pages/mail/ViewEmailOnline";
import { Unsubscribe } from "pages/account/Unsubscribe";
import Account from "pages/account";
import { Logout } from "pages/account/Logout";
import { Helmet } from "react-helmet-async";
import { config } from "config";

function App() {
    return (
        <ErrorBoundary>
            {config.isProduction &&
                <Helmet>
                    <meta
                        httpEquiv="Content-Security-Policy"
                        content={`
                      default-src 'self' ${config.apiUrl} ${config.siteOrigin} https://monitorv3config.azconfig.io  https://login.microsoftonline.com https://*.unlayer.com https://www.googleapis.com;
                      script-src 'self' https://*.unlayer.com;
                      style-src 'self' 'unsafe-inline' https://fonts.googleapis.com;
                      img-src 'self' data: https://*.unlayer.com https://app.monitorcrms.com https://appca.monitorcrms.com https://appbeta.monitorcrms.com;
                      media-src 'self';
                      font-src 'self' https://fonts.gstatic.com data:;
                      form-action 'self';
                    `}
                    ></meta>
                </Helmet>
            }
            <div className={'app-container  '}>
                <Alert />
                <Switch>
                    <Route path="/view-email-online" component={ViewEmailOnline} />
                    <Route path="/account/unsubscribe" component={Unsubscribe} />
                    <Route path="/account/logout" component={Logout} />
                    <Route path="/account" component={Account} />
                    <AuthenticatedLayout />
                </Switch>
            </div>
        </ErrorBoundary>
    );
}

export default App;