import { Role } from 'helpers';
import { HeaderNav } from 'layouts/HeaderNav';
import { MainNav } from 'layouts/MainNav';
import { NavBurger } from 'layouts/NavBurger';
import { useEffect, useState } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { accountService } from 'services';

import PrivateRoute from 'layouts/PrivateRoute';
import { Admin } from 'pages/admin';
import { PrivateRoutesRenderAll } from './PrivateRoutesRenderAll';
import { Container } from 'react-bootstrap';
import { SidebarPanelProvider } from 'components/SidebarPanel/SidebarPanelContext';

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from 'pages/account/msAuth/authConfig';
import { MsalProvider } from '@azure/msal-react';

import HelpToggle from 'components/Help/HelpToggle';
import { LandingLoadingLayout } from './LandingLoadingLayout';

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
//const msalInstance = new PublicClientApplication(msalConfig);

const AuthenticatedLayout = (props) => {
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(true);
    // const [msalInstance, setMsalInstance] = useState(null)

    useEffect(() => {
        //get query string of encrypted user id
        const params = new URLSearchParams(window.location.search);
        const email = params.get('email');
        const password = params.get('password');
        const clientId = params.get('clientId') ?? '0';

        if (email != null && password != null) {
            accountService.authenticateV2User({ email, password, clientId})
                .then(() => {
                    accountService.refreshToken()
                        .catch(err => null)
                        .finally(() => setLoading(false));
                })
                .catch(err => {
                    //revoke
                    accountService.refreshToken()
                        .then(() => accountService.logout())
                        .catch(err => setLoading(false))
                });
        }
        else {
            // attempt silent token refresh before startup
            accountService.refreshToken()
                .catch(err => null)
                .finally(() => setLoading(false));
        }
    }, []);

    return (
        <>
            {loading &&
                <LandingLoadingLayout loading={loading}></LandingLoadingLayout>
            }
            {
                !loading &&
                // <MsalProvider instance={msalInstance}>
                <div className="authenticated-layout ">
                    <div className="layout  ">
                        {/* <nav className="layout-main-nav" data-testid="main-nav">
                            <a href="/" className="main-logo"></a>

                            <div className="layout-main-nav-list">
                                <MainNav />

                            </div>
                        </nav> */}
                        <div className="layout-main layout-main-no-nav  ">
                            {/* <header className="layout-main-header">
                                <NavBurger />
                                <HeaderNav />
                            </header> */}
                            <SidebarPanelProvider>
                                <main className="layout-main-content">
                                    <Container fluid>
                                        <Switch>
                                            <PrivateRoutesRenderAll></PrivateRoutesRenderAll>

                                            <PrivateRoute path="/admin" roles={[Role.Admin]} component={Admin} />

                                            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                                            <Redirect from="*" to="/" />
                                        </Switch>
                                        {/* <HelpToggle /> */}
                                    </Container>
                                </main>
                            </SidebarPanelProvider>
                        </div>

                    </div>
                </div>
                // </MsalProvider>
            }
        </>
    )
}

export default AuthenticatedLayout;