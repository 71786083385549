import LoadingProgressLinear from 'components/LoadingProgressLinear';
import logo from 'assets/images/MonitorCRMS_Logo.png';

export const LandingLoadingLayout = ({ loading }) => {

    return (

        <>
            <div className="initial-loading" style={{
                "display": "flex",
                "alignItems": "center",
                "justifyContent": "center",
                "height": "100vh",
                "flexDirection": "column"
            }}>
                <img src={logo} alt="MonitorCRMS Logo" />
                <div style={{
                    "position": "relative",
                    "width": "110px",
                    "marginTop": "20px"
                }}>
                    <LoadingProgressLinear isLoading={loading} style={{
                        width: "100px"
                    }} />
                </div>
            </div >
        </>
    )

}