//import reportWebVitals from './reportWebVitals';

import React  from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import { history } from './helpers';
import App from './App';
import './scss/App.scss';
import { HelmetProvider } from 'react-helmet-async';

//import './styles.less';

const root = createRoot(document.getElementById('root'));
root.render(
  // <StrictMode>
    <HelmetProvider>
      <Router history={history}>
        <App />
      </Router>
    </HelmetProvider>
  /* </StrictMode> */
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();