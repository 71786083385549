import { BehaviorSubject } from 'rxjs';
import { config } from 'config';
import { fetchWrapper } from 'helpers';

const campaignsSubject = new BehaviorSubject(null);
const sendingLimitSubject = new BehaviorSubject(null);
const apiUrl = `${config.apiUrl}/api/campaign`;

export const campaignsService = {
    get campaignValue() { return campaignsSubject.value },
    getById: function (id) {
        let url = `${apiUrl}/${id}`
        return fetchWrapper.get(url)
            .then(campaign => {
                campaignsSubject.next(campaign);
                return campaign;
            });
    },
    get: function (page, pageSize) {
        const url = `${apiUrl}?page=${page}&pageSize=${pageSize}`;
        return fetchWrapper.get(url);
    },
    getByFilters: function (param) {
        return fetchWrapper.post(`${apiUrl}/GetByFilters`, param);
    },
    create: function (data) {
        //add api post code
        return fetchWrapper.post(apiUrl, data);
    },
    update: function (data) {
        //add api post code
        const url = `${apiUrl}/${data.id}`;
        return fetchWrapper.put(url, data);
    },
    delete: function (id) {
        let url = `${apiUrl}/${id}`;
        return fetchWrapper.delete(url);
    },
    archive: async function (id) {
        const url = `${apiUrl}/Archive/${id}`;
        return await fetchWrapper.delete(url);
    },
    unarchive: async function (id) {
        const url = `${apiUrl}/Unarchive/${id}`;
        return await fetchWrapper.put(url);
    },
    executeProcessActivity: function (campaignId, activityId) {
        let url = `${apiUrl}/ExecuteProcessActivity`;
        //create body object
        const body = {
            campaignId: campaignId,
            campaignActivityId: activityId
        }

        return fetchWrapper.post(url, body);
    },
    getProcessActivityStatus: function (campaignId, activityId) {
        let url = `${apiUrl}/GetDurableFunctionHistory`;

        //create query string
        const queryString = `?campaignId=${campaignId}&campaignActivityId=${activityId}`;

        return fetchWrapper.get(url + queryString);
    },

    reExecuteProcessActivity: function (campaignId, activityId, durableFunctionUrl) {
        let url = `${apiUrl}/ReExecuteProcessActivity`;

        //create body object
        const body = {
            campaignId: campaignId,
            campaignActivityId: activityId,
            durableFunctionUrl: durableFunctionUrl
        }

        return fetchWrapper.post(url, body);
    },

    viewStatusProcessActivity: function (campaignId, activityId, durableFunctionUrl) {
        let url = `${apiUrl}/ViewStatusProcessActivity`;

        //create body object
        const body = {
            campaignId: campaignId,
            campaignActivityId: activityId,
            durableFunctionUrl: durableFunctionUrl
        }

        return fetchWrapper.post(url, body);
    },

    getTotalByStatus: function (campaignId, includeArchivedActivities, abortSignal) {
        let url = `${apiUrl}/GetTotalByStatus?campaignId=${campaignId}&includeArchivedActivities=${includeArchivedActivities}`;

        return fetchWrapper.get(url, abortSignal);
    },

    getTotalActivities: function (campaignId, includeArchivedActivities, abortSignal) {
        let url = `${apiUrl}/GetTotalActivities?campaignId=${campaignId}&includeArchivedActivities=${includeArchivedActivities}`;

        return fetchWrapper.get(url, abortSignal);
    },


    sendPreviewEmails: function (activity, emails) {
        let url = `${apiUrl}/SendPreviewEmails`;

        //create body object
        const body = {
            activity: activity,
            emails: emails
        }

        return fetchWrapper.post(url, body);
    },

    getRateStats: function (campaignId, includeArchivedActivities, abortSignal ) {
        let url = `${apiUrl}/GetRateStats?campaignId=${campaignId}&includeArchivedActivities=${includeArchivedActivities}`;

        return fetchWrapper.get(url, abortSignal);
    },
    searchByName:function(param){
        return fetchWrapper.post(`${apiUrl}/SearchByName`, param);
    },
    checkClientSendLimitRemaining: function (clientId) {
        return fetchWrapper.get(`${apiUrl}/CheckClientSendLimitRemaining?clientId=${clientId}`)
        .then(sendingLimit => {
            sendingLimitSubject.next(sendingLimit);
            return sendingLimit;
        });
    },
    sendingLimit:  sendingLimitSubject.asObservable(),
}