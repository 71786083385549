import React, { Suspense, lazy } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

const Business = lazy(() => import("pages/business/Business"));
const Activity = lazy(() => import("pages/campaigns/Activity"));
const ActivityForm = lazy(() => import("pages/campaigns/ActivityForm"));
const Campaigns = lazy(() => import("pages/campaigns/Campaigns"));
const CampaignsForm = lazy(() => import("pages/campaigns/CampaignsForm"));
const Contacts = lazy(() => import("pages/contact/Contacts"));
const Engagement = lazy(() => import("pages/engagement/Engagement"));
const Home = lazy(() => import("pages/home"));
const Profile = lazy(() => import("pages/profile"));
const Site = lazy(() => import("pages/Site/Site"));
const LoadingProgressLinear = lazy(() => import("components/LoadingProgressLinear"));

export const PrivateRoutesRenderAll = () => {
    return (
        <Suspense fallback={<LoadingProgressLinear isLoading={true} />}>
            <Switch>

                {/* <PrivateRoute path="/contact/:id/edit" component={ContactsForm}></PrivateRoute>
            <PrivateRoute path="/contact/new" component={ContactsForm}></PrivateRoute> */}
                <PrivateRoute path="/contacts" component={Contacts} />

                {/* <PrivateRoute path="/business/:id/edit" component={BusinessForm}></PrivateRoute>
            <PrivateRoute path="/business/new" component={BusinessForm}></PrivateRoute> */}
                <PrivateRoute path="/business" component={Business} />

                {/* <PrivateRoute path="/property/:id/edit" component={PropertyForm}></PrivateRoute>
            <PrivateRoute path="/property/new" component={PropertyForm}></PrivateRoute> */}
                <PrivateRoute path="/property" component={Site} />

                {/* <PrivateRoute path="/engagement/:id/edit" component={EngagementForm}></PrivateRoute>
            <PrivateRoute path="/engagement/new" component={EngagementForm}></PrivateRoute> */}
                {/* <PrivateRoute path="/engagement" component={Engagement} /> */}

                <PrivateRoute path="/campaigns/:campaignsId/activity/:id/edit" component={ActivityForm}></PrivateRoute>
                <PrivateRoute path="/campaigns/:campaignsId/activity/new" component={ActivityForm}></PrivateRoute>
                <PrivateRoute path="/campaigns/:campaignsId/activity/:id?" component={Activity}></PrivateRoute>

                <PrivateRoute path="/campaigns/:id/edit" component={CampaignsForm}></PrivateRoute>
                <PrivateRoute path="/campaigns/new" component={CampaignsForm}></PrivateRoute>
                <PrivateRoute path="/campaigns/:id?" component={Campaigns} />
                <PrivateRoute exact path="/" component={Campaigns} />

{/*
                <PrivateRoute path="/profile" component={Profile} />
                <PrivateRoute exact path="/" component={Home} /> */}

            </Switch>
        </Suspense>
    );
}
