import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { helpCommentService } from "services/helpComment.service";

const { Toast, Button, Stack } = require("react-bootstrap")

const HelpComment = (props) => {
    const { comment } = props
    const { reloadComments } = props

    const createdDate = moment(comment?.createdDate).fromNow();
    const modifiedDate = moment(comment?.modifiedDate).fromNow();

    const deleteComment = () => {
        helpCommentService.delete(comment.id).then(() => {
            reloadComments();
        })
    }

    const publishComment = () => {
        helpCommentService.publish(comment.id).then(() => {
            reloadComments();
        })
    }

    const unPublishComment = () => {
        helpCommentService.unPublish(comment.id).then(() => {
            reloadComments();
        })
    }

    return (
        <Toast {...props} className="animate-show">
            <Toast.Header closeButton={false}>
                <strong className="me-auto">{comment?.createdByName || '(User Name)'}</strong>
                <Stack direction="horizontal" gap={1}>
                    <small>{createdDate}</small>
                    {comment.isPublished && <FontAwesomeIcon icon={faCheckCircle} />}
                </Stack>
            </Toast.Header>
            <Toast.Body>
                {comment.message}
                <hr />
                <Stack direction="horizontal" gap={1}>
                    <Button variant="outline-danger" onClick={deleteComment} size="sm">Delete</Button>
                    {!comment.isPublished
                        ? <Button variant="outline-success" onClick={publishComment} size="sm">Publish</Button>
                        : <Button variant="outline-success" onClick={unPublishComment} size="sm">UnPublish</Button>
                    }
                </Stack>
                <small>Updated by: {comment.modifiedByName} {modifiedDate}</small>
            </Toast.Body>

        </Toast>
    )
}


export default HelpComment