import { createContext, useContext, useReducer } from "react";

export const SidebarPanelContext = createContext(null);
export const SidebarPanelDispatchContext = createContext(null);

const reducer = (state, action) => {
    return action;
}

const initialState = null;

export const SidebarPanelProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <SidebarPanelContext.Provider value={state}>
            <SidebarPanelDispatchContext.Provider value={dispatch}>
                {children}
            </SidebarPanelDispatchContext.Provider>
        </SidebarPanelContext.Provider>
    );
}


export const useSidebarPanelContext = () => {
    return  useContext(SidebarPanelContext);
}

export const useSidebarPanelDispatchContext = () => {
    return  useContext(SidebarPanelDispatchContext);
}

