import { Stack } from 'react-bootstrap';

const PlainClientLogoLayout = ({ clientLogoPath, ...props }) => {
    const yearToday = new Date().getFullYear();

    const baseUrl = process.env.REACT_APP_V2APP_URL;
    const logoPath = `${baseUrl}/assets/images/clients/${clientLogoPath}`;

    return (
        <>
            <Stack className="unsubscribe-layout">
                {clientLogoPath &&
                    <div className="client-logo"
                        style={{
                            margin: "100px auto"
                        }}
                    >
                        <img src={logoPath} alt="Client Logo"
                        style={{
                            maxWidth: "200px"
                        }}
                        />
                    </div>
                }
                <div className="unsubscribe-bottom"

                >
                    {props.children}
                </div>

            </Stack>
        </>
    )
}

export default PlainClientLogoLayout;