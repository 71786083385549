import { history } from "helpers";
import { useEffect } from "react";
import { accountService } from "services";

export const Logout = () => {
    useEffect(() => {
        accountService.refreshToken().then(() => {
            accountService.logout();
        }).catch(() => {
            history.push('/account/login');
        });
    }, []);
}